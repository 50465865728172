/********************** Inicio **********************/

.contenido {
    position: relative;
    background: #010101;
    width: 100vw;
    height: 100vh;
    background-position: center center;
	background-size: cover;
}

.inicio{
    position: relative;
    display: flex;
    justify-content: center;
    z-index: 2;
    pointer-events: none;
}

.inicio .titulo{
    margin: 20rem auto 0 auto;
    text-align: center;
    z-index: 200!important;
}

.inicio .titulo p {
    font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
    font-size: 2.5rem;
    color: var(--white);
    position: relative;
    font-weight:lighter;
    pointer-events: none;
}

.inicio .titulo h1{
    font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
    font-size: 9rem;
    color: var(--green);
    font-weight: bolder;
    letter-spacing: 0.3rem;
    pointer-events: none;
}

.wrapper {
    margin: 2rem;
}
.wrapper .button{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    height: 60px;
    width: 60px;
    float: left;
    margin: 0 2rem;
    overflow: hidden;
    color: #000;
    background: var(--green);
    border-radius: 50px;
    cursor: pointer;
    box-shadow: 0px 10px 10px rgba(0,0,0,0.1);
}
.wrapper .button .icon{
    display: flex;
    padding: 0 0;
    height: 60px;
    width: 60px;
    border-radius: 50px;
    box-sizing: border-box;
    line-height: 60px;
    transition: all 1.5s ease-out!important;
    justify-content: center;
}
.wrapper .button:nth-child(1):hover .icon{
    background: #ff0000;
}
.wrapper .button:nth-child(2):hover .icon{
    background: #4267B2;
}
.wrapper .button:nth-child(3):hover .icon{
    background: #333;
}
.wrapper .button:nth-child(4):hover .icon{
    background: #E1306C;
}

.wrapper .button .icon i{
    font-size: 25px;
    line-height: 60px;
    transition: all 0.3s ease-out;
}
.wrapper .button:hover .icon i{
    color: #fff;
}

.wrapper .button:nth-child(1):hover{
    background-color: #ff0000;
}
.wrapper .button:nth-child(2):hover{
    background-color: #4267B2;
}
.wrapper .button:nth-child(3):hover{
    background-color: #333;
}
.wrapper .button:nth-child(4):hover{
    background-color: #E1306C;
}

.titulo .redes-sociales{
    display: none;
}

.scroll-down {
    height: 50px;
    width: 30px;
    border: 2px solid #fff;
    position: absolute;
    bottom: -80%;
    left: 48%;
    border-radius: 50px;
    cursor: pointer;
    pointer-events: auto;
}
.scroll-down::before {
    content: "";
    position: absolute;
    left: 25%;
    top: 35%;
    height: 10px;
    width: 10px;
    transform: translate(-50%, -100%) rotate(45deg);
    border: 2px solid #fff;
    border-radius: 50%;
    animation: scroll-down 2s ease-in-out infinite;
}

@keyframes scroll-down {
    0%, 100% {
        transform: translateY(-100%);
    }
    50% {
        transform: translateY(100%);
    }
}


/********************** Media Q **********************/

@media (max-width: 990px) {
    .wrapper .button .icon{
        display: inline-block;
        padding: 0 1.8rem 0 2.1rem;
        height: 60px;
        width: 60px;
        border-radius: 50px;
        box-sizing: border-box;
        line-height: 60px;
        transition: all 0.3s ease-out;
    }
}

@media (max-width: 760px) {

    .contenido {
        min-height: 600px;
    }

    .inicio .titulo p {
        font-size: 3.5rem;
    }
    
    .inicio .titulo h1{
        font-size: 4rem;
    }

    .scroll-down {
        left: 46%;
    }
}


.background_layer{
    position: absolute; /* Positioned element */
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
}

.background_layer::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 150px; /* Adjust this to control the height of the fade */
    pointer-events: none;
    background: linear-gradient(to top, rgb(14, 12, 12), transparent);
  }

.about_section {
    position: relative;
    width: 100%;
    height: 100vh;
    background: #111;
    display: flex;
    flex-direction: column;
    overflow: hidden;
}

.about_section .row {
    position: relative;
    top: -50%;
    width: 100%;
    display: flex;
    padding: 10px;
    white-space: nowrap;
    font-size: 64px;
    transform: rotate(-30deg);
}

.fa {
    color: rgba(0,0,0,0.5);
    transition: 1s;
    padding: 0 5px;
    user-select: none;
    cursor: default;
}

.fa:hover {
    transition: 0s;
    color: var(--green);
    text-shadow: 0 0 120px var(--green);
}

.about_section .row div{
    animation: animate1 80s linear infinite;
    animation-delay: -80s;
}

.about_section .row div:nth-child(2){
    animation: animate2 80s linear infinite;
    animation-delay: -40s;
}

@keyframes animate1 {
    0%{
        transform: translateX(100%);
    }
    100%{
        transform: translateX(-100%);
    }
}

@keyframes animate2 {
    0%{
        transform: translateX(0%);
    }
    100%{
        transform: translateX(-200%);
    }
}


.about_section .row:nth-child(even) div{
    animation: animate3 80s linear infinite;
    animation-delay: -80s;
}

.about_section .row:nth-child(even) div:nth-child(2){
    animation: animate4 80s linear infinite;
    animation-delay: -40s;
}

@keyframes animate3 {
    0%{
        transform: translateX(-100%);
    }
    100%{
        transform: translateX(100%);
    }
}

@keyframes animate4 {
    0%{
        transform: translateX(-200%);
    }
    100%{
        transform: translateX(0%);
    }
}
