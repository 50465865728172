.timeline {
    position: relative;
    max-width: 1200px;
    margin: 0 auto;
}

.timeline::after {
    content: '';
    position: absolute;
    width: 3px;
    background-color: #f5f5f5;
    top: 0;
    bottom: 0;
    margin-left: -2px;
}

.timeline-item {
    padding: 10px 20px;
    position: relative;
    background-color: inherit;
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
}

.timeline-item-content {
    padding: 20px 30px;
    border-radius: 6px;
    width: 45%;
}

.timeline-item-explanation {
    font-size: 1.7rem;
    line-height: 1.5;
    color: var(--light-color);
    padding: 20px 30px;
    position: relative;
    border-radius: 6px;
    border: 1px solid #f5f5f5;
    width: 55%;
}

.timeline-item-explanation p{
    text-transform: none;
}



.circle {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background: #f5f5f5;
    position: absolute;
    left: 0;
    transform: translateX(-50%);
    top: calc(50% - 10px);
    border: 5px solid rgb(14, 12, 12);
    z-index: 1;
}

.timeline-date {
    font-size: 1.5em;
    color: var(--white);
}

.timeline-title {
    font-size: 2.5em;
    color: var(--green);
}

.timeline-basicInfo {
    font-size: 1.8em;
    color: var(--light-color);
}

.timeline-item:nth-child(all) .timeline-item-content {
    margin-left: 60px;
}

@media (max-width: 760px) {
    .timeline-item {
        flex-direction: column;
    }
    .timeline-item-explanation {
        width: 90%;
        margin-left: 20px;
    }
    .timeline-item-content {
        width: 90%;
    }
}