

section .row{
    gap: 1.5rem;
}

section .row .columns{
    display: flex;
    flex-direction: column;
    padding: 1rem 1rem 0 1rem;
    background: #fff;
    box-shadow: var(--box-shadow);
    border: var(--borde);
    border-radius: .5rem;

}


section .row .col-skill{
    margin-top: 3rem;
}

#sobre-mi .row{
    overflow-x: hidden!important;
}

section .row .columns h3{
    font-size: 2rem;
    color: var(--light-color);
    margin-bottom: 2rem;
}

section .row .columns h4{
    font-size: 2.7rem;
    color: var(--black);
    margin-bottom: 2rem;
    text-transform: none;
}

section .row .columns p{
    font-size: 1.7rem;
    line-height: 2;
    margin-bottom: 3rem;
    text-transform: none;
}

section .row .columns ul li{
    list-style:none;
}

section .row .columns ul li p span{
    font-weight: bold;
    color: var(--black);
}

section .row .columns .mas-info{
    display: flex;
    flex-direction: column;
    margin: 0 0 2rem 0;
}

.mas-info-btn{
    display: flex;
}

.sobre-mi .row .columns a{
    margin: 2rem 1rem 1rem 0;
}

section .row .col-skill h4{
    font-size: 2.7rem;
    color: var(--black);
    margin-bottom: 2.5rem;
}

.skill{
    display: flex;
    flex-wrap: wrap;
}


.skill h5{
    background: var(--black);
    text-align: center;
    border-radius: .5rem;
    padding: .5rem 0;
    font-size: 1.1rem;
    width: 10rem;
    margin: 0 1.5rem;
    transform: translate(0px, -75px);
    opacity: 0;
    transition: .5s ease all;
    color: var(--white);
}

.skill>div:hover h5 {
    transform: translate(0px, -110px);
    opacity: 1;
}

.icons-skils {
    width: 7rem;
    height: 7rem;
    margin: .5rem 3rem;
    cursor: pointer;
}

.animated-progress {
    margin-top: 1rem;
    margin-left: 15%;
    margin-right: 15%;
    width: 70%;
    height: 1.5rem;
    bottom: 2rem;
    border-radius: .5rem;
    overflow: hidden;
    position: relative;
    background-color: #000;
    text-align: center;
    box-shadow: 0 0 8px 4px var(--green);
    visibility: hidden;
  }

  
.animated-progress span {
    height: 100%;
    display: block;
    width: 0;
    border-radius: .5rem;
    background-color: var(--green); /* color to represent progress */
    position: absolute;
    line-height: 1.8rem; /* same as the height of the container */
    color: black;
    transition: width 1s ease-in-out;
}

section .row .columns .skill img:hover {
    transform: scale(1.2);
}

@keyframes slideDown {
    0% {
        transform: translateY(-200%);
        opacity: 0;
        visibility: hidden;
    }
    100% {
        transform: translateY(0);
        opacity: 1;
        visibility: visible;
    }
}

@keyframes slideUp {
    0% {
        transform: translateY(0);
        opacity: 1;
        visibility: visible;
    }
    100% {
        transform: translateY(-200%);
        opacity: 0;
        visibility: hidden;
    }
}


.animated-progress.slideDown {
    animation: slideDown 0.5s forwards;
}

.animated-progress.slideUp {
    animation: slideUp 0.5s forwards;
}

/********************** Sobre-mi-page **********************/

.sobre-mi-seccion{
    width: 100%;
    padding-top: 7rem;
    position: relative;
    top: 0;
    transition: 1s;
    margin-bottom: 2rem;
    overflow: hidden!important;
}

.sobre-mi-container {
    display: grid;
    grid-template-columns: 30% 68%;
    grid-gap: 2rem;
}

.sobre-mi-img-container{
    position: relative;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    -webkit-align-items: flex-end;
}

.sobre-mi-info p{
    color: #000;
    font-size: 1.7rem;
    line-height: 2;
    text-transform: none;
    padding-bottom: 1rem;
}

.sobre-mi-info .btn-info {
    display: none;
}


.sobre-mi-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: right;
    border-radius: 2rem;
}

.cv {
    position: absolute;
    margin-bottom: 2rem;
}

.cv:hover{
    background: var(--white);
    color: var(--black);
}


/********************** Skill page **********************/

.skill-container{
    margin: auto;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 5rem;
}

.skills-img {
    display: block;
    margin: 0 auto;
    height: 15rem;
}

.skill-name{
    font-size: 3rem;
    font-weight: 500;
    text-align: center;
    margin: 2rem 0 2rem;
    color: var(--black);
}

.skill-info{
    text-align: center;
    color: #000;
    font-size: 1.7rem;
    line-height: 2;
    text-transform: none;
}

/********************** Media Q **********************/
